import React from 'react';
import Layout from './components/v2/Layout';
import Hero from './components/v2/Hero';
import Feature from './components/v2/Feature';
import PricingSection from './components/v2/PricingSection';
import TestimonialSection from './components/v2/TestimonialSection';

const HomePageV2: React.FC = () => {
    return (
        <Layout>
            <Hero />
            <Feature
                title="Project Management"
                description="Images, videos, PDFs and audio files are supported. Create math expressions and diagrams directly from the app. Take photos with the mobile app and save them to a note."
                buttonText="Get Started"
                imagePosition="right"
            />
            <Feature
                title="Work together"
                description="With whitepace, share your notes with your colleagues and collaborate on them. You can also publish a note to the internet and share the URL with others."
                buttonText="Try it now"
                imagePosition="left"
            />
            <Feature
                title="Customise it to your needs"
                description="Customise the app with plugins, custom themes and multiple text editors (Rich Text or Markdown). Or create your own scripts and plugins using the Extension API."
                buttonText="Let's Go"
                imagePosition="right"
            />
            <PricingSection />
            <TestimonialSection />
        </Layout>
    );
};

export default HomePageV2;
