import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import ProTip from './ProTip';
import LandingPage from "./LandingPage";
import LandingPageV3 from "./components/v3/Landingpage";
import HomePage from "./HomePage";
import HomePageV2 from "./HomePageV2";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

export default function App() {
  return (
      <Router>
          <Routes>
              <Route path="/" element={<LandingPageV3 />} />
              <Route path="/v2" element={<LandingPage />} />
              <Route path="/v3" element={<HomePageV2 />} />
              {/*<Route path="/pricing" element={<LandingPage />} />*/}
              {/*<Route path="/onboarding" element={<LandingPage />} />*/}
              {/*<Route path="/contact" element={<LandingPage />} />*/}
              {/*<Route path="/signin" element={<LandingPage />} />*/}
          </Routes>
      </Router>
  );
}
