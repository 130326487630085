import React from 'react';
import styled from '@emotion/styled';
import PricingCard from './PricingCard';

interface PricingSectionProps {
    // Add any props if needed
}

const Section = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 80px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 40px 20px;
  }
`;

const Title = styled.h2`
  color: #212529;
  text-align: center;
  letter-spacing: -1.44px;
  margin-top: 60px;
  font: 700 72px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    font-size: 40px;
  }
`;

const Description = styled.p`
color: #212529;
text-align: center;
letter-spacing: -0.36px;
margin-top: 24px;
width: 979px;
font: 400 18px/30px Inter, sans-serif;
@media (max-width: 991px) {
    max-width: 100%;
}
`;

const PricingCardsContainer = styled.div`
width: 100%;
max-width: 1481px;
margin: 60px 0 32px;
display: flex;
justify-content: space-between;
@media (max-width: 991px) {
    max-width: 100%;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}
`;

const PricingSection: React.FC<PricingSectionProps> = () => {
  const pricingPlans = [
    {
      title: "Free",
      price: "$0",
      features: [
        "Sync unlimited devices",
        "10 GB monthly uploads",
        "200 MB max. note size",
        "Customize Home dashboard and access extra widgets",
        "Connect primary Google Calendar account",
        "Add due dates, reminders, and notifications to your tasks"
      ],
      buttonText: "Get Started"
    },
    {
      title: "Personal",
      price: "$11.99",
      features: [
        "Sync unlimited devices",
        "10 GB monthly uploads",
        "200 MB max. note size",
        "Customize Home dashboard and access extra widgets",
        "Connect primary Google Calendar account",
        "Add due dates, reminders, and notifications to your tasks"
      ],
      buttonText: "Get Started",
      highlighted: true
    },
    {
      title: "Organization",
      price: "$49.99",
      features: [
        "Sync unlimited devices",
        "10 GB monthly uploads",
        "200 MB max. note size",
        "Customize Home dashboard and access extra widgets",
        "Connect primary Google Calendar account",
        "Add due dates, reminders, and notifications to your tasks"
      ],
      buttonText: "Get Started"
    }
  ];

  return (
    <Section>
      <Title>Choose Your Plan</Title>
      <Description>
        Whether you want to get organized, keep your personal life on track, or boost workplace productivity, Evernote has the right plan for you.
      </Description>
      <PricingCardsContainer>
        {pricingPlans.map((plan, index) => (
          <PricingCard key={index} {...plan} />
        ))}
      </PricingCardsContainer>
    </Section>
  );
};

export default PricingSection;
