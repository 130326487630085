import React from 'react';
import styled from '@emotion/styled';
import Logo from './Logo';
import Navigation from './Navigation';
import Button from './Button';

interface HeaderProps {
    // Add any props if needed
}

const HeaderWrapper = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 16px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  width: 100%;
  max-width: 1480px;
  gap: 20px;
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const Header: React.FC<HeaderProps> = () => {
    return (
        <HeaderWrapper>
            <HeaderContent>
                <Logo />
                <Navigation />
                <Button variant="secondary" text="Login" />
                <Button variant="primary" text="Try Whitepace free" icon="arrow-right" />
            </HeaderContent>
        </HeaderWrapper>
    );
};

export default Header;
