import React from 'react';
import styled from '@emotion/styled';

interface ButtonProps {
    variant: 'primary' | 'secondary';
    text: string;
    icon?: string;
}

const ButtonWrapper = styled.button<{ variant: 'primary' | 'secondary' }>`
  border-radius: 8px;
  display: flex;
  gap: 10px;
  color: ${props => props.variant === 'primary' ? '#fff' : '#043873'};
  background-color: ${props => props.variant === 'primary' ? '#4f9cf9' : '#ffe492'};
  font-size: 18px;
  font-weight: 500;
  line-height: 128%;
  justify-content: center;
  padding: 16px 24px;
  border: none;
  cursor: pointer;
  @media (max-width: 991px) {
    padding: 16px 20px;
  }
`;

const ButtonText = styled.span`
  font-family: Inter, sans-serif;
`;

const ButtonIcon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 10px;
  margin: auto 0;
`;

const Button: React.FC<ButtonProps> = ({ variant, text, icon }) => {
    return (
        <ButtonWrapper variant={variant}>
            <ButtonText>{text}</ButtonText>
            {icon && <ButtonIcon loading="lazy" src={`http://b.io/ext_${icon}-`} alt="" />}
        </ButtonWrapper>
    );
};

export default Button;
