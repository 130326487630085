import React from 'react';
import styled from '@emotion/styled';
import Header from './Header';
import Hero from './Hero';
import Features from './Feature1';
import BusinessBoost from './BusinessBoost';
import FactBasedKnowledge from './Features3';
import Testimonials from './Testimonials';
import SupportTeam from './SupportTeam';
import ContactUs from './ContactUs';
import Footer from './Footer';

const LandingPageV3: React.FC = () => {
    return (
        <PageWrapper>
            <Header />
            <Hero />
            <Features />
            <BusinessBoost />
            {/*<FactBasedKnowledge />*/}
            {/*<Testimonials />*/}
            {/*<SupportTeam />*/}
            {/*<ContactUs />*/}
            <Footer />
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
`;

export default LandingPageV3;
