import React from 'react';
import styled from '@emotion/styled';

const Header: React.FC = () => {

    return (
        <HeaderWrapper>
            <Logo>
                <LogoImage src="https://cdn.builder.io/api/v1/image/assets/TEMP/91cef9defd16c85fcd5ea62ba5833c04fd1cd92b465800ad6bd92a3527ff63d9?apiKey=dc27afe0f89846c5bd7a442e6ae7c012&&apiKey=dc27afe0f89846c5bd7a442e6ae7c012" alt="Knowlio Logo" />
                <LogoText>Knowlio</LogoText>
            </Logo>
            {/*<CTAButton>*/}
            {/*    <CTAText>Get Started</CTAText>*/}
            {/*</CTAButton>*/}
        </HeaderWrapper>
    );
};

const HeaderWrapper = styled.header`
  display: flex;
  width: 100%;
  max-width: 1300px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Logo = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;
`;

const LogoImage = styled.img`
  width: 27px;
  height: 37px;
  object-fit: contain;
`;

const LogoText = styled.span`
  font-family: 'Saira Stencil One', sans-serif;
  font-size: 40px;
  color: #0f2137;
`;

const Navigation = styled.nav`
  display: flex;
  gap: 20px;
  font-size: 16px;
  color: black;
  align-content: end;
`;

const NavItem = styled.a`
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  text-decoration-color: black;
`;

const CTAButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
`;

const CTAText = styled.span`
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #f94962;
`;

const CTAIcon = styled.img`
  width: 89px;
  margin-top: 8px;
`;

export default Header;
