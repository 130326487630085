import React from 'react';
import styled from '@emotion/styled';
import TestimonialCard from './TestimonialCard';

interface TestimonialSectionProps {
    // Add any props if needed
}

const Section = styled.section`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 80px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 40px 20px;
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1480px;
  flex-direction: column;
  margin: 60px 0 32px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Title = styled.h2`
  color: #212529;
  text-align: center;
  letter-spacing: -1.44px;
  font: 700 72px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
  }
`;

const TestimonialContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 60px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
`;

const NavigationDots = styled.div`
  align-self: center;
  display: flex;
  margin-top: 60px;
  width: 250px;
  max-width: 100%;
  gap: 20px;
  justify-content: space-between;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Dot = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 75px;
`;

const TestimonialSection: React.FC<TestimonialSectionProps> = () => {
    const testimonials = [
        {
            content: "If you haven't tried whitepace yet, you need to give it a shot for your next event. It's so easy and intuitive to get a new event setup and if you need any help their customer service is seriously amazing.",
            author: "Jessie Owner",
            position: "Founder, XYZ Company"
        },
        {
            content: "If you haven't tried whitepace yet, you need to give it a shot for your next event. It's so easy and intuitive to get a new event setup and if you need any help their customer service is seriously amazing.",
            author: "Jessie Owner",
            position: "Founder, XYZ Company",
            highlighted: true
        },
        {
            content: "If you haven't tried whitepace yet, you need to give it a shot for your next event. It's so easy and intuitive to get a new event setup and if you need any help their customer service is seriously amazing.",
            author: "Jessie Owner",
            position: "Founder, XYZ Company"
        }
    ];

    return (
        <Section>
            <Container>
                <Title>See what our trusted users Say</Title>
    <TestimonialContainer>
    {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} {...testimonial} />
))}
    </TestimonialContainer>
    <NavigationDots>
    <Dot loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/9552c909e6bc6bbfc7fb5f9ffeebf723e08a16021df1f332215cc57726a2a912?apiKey=dc27afe0f89846c5bd7a442e6ae7c012&&apiKey=dc27afe0f89846c5bd7a442e6ae7c012" alt="" />
    <Dot loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/a95020411e65fa48e3b23b8c9c49b4fcf4b0a00939f4744d68e6ced7048d639d?apiKey=dc27afe0f89846c5bd7a442e6ae7c012&&apiKey=dc27afe0f89846c5bd7a442e6ae7c012" alt="" />
        </NavigationDots>
        </Container>
        </Section>
);
};

export default TestimonialSection;
