import React from 'react';
import styled from '@emotion/styled';

interface TestimonialCardProps {
    content: string;
    author: string;
    position: string;
    highlighted?: boolean;
}

const Card = styled.div<{ highlighted?: boolean }>`
  border-radius: 10px;
  background-color: ${props => props.highlighted ? '#4f9cf9' : '#fff'};
  box-shadow: ${props => props.highlighted ? 'none' : '10px 10px 50px rgba(0, 0, 0, 0.08)'};
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  max-width: 400px;
  padding: 60px 40px;
  color: ${props => props.highlighted ? '#fff' : '#01252a'};
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 32px;
    padding: 40px 20px;
  }
`;

const Avatar = styled.img`
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 70px;
  border-radius: 50%;
`;

const Content = styled.p`
  letter-spacing: -0.36px;
  margin-top: 35px;
  font: 400 18px/30px Inter, sans-serif;
`;

const AuthorInfo = styled.div`
  display: flex;
  margin-top: 49px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const AuthorDetails = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  letter-spacing: -0.36px;
`;

const AuthorName = styled.span`
  font-family: Inter, sans-serif;
  font-weight: 700;
`;

const AuthorPosition = styled.span`
  font-family: Inter, sans-serif;
  font-weight: 400;
  line-height: 167%;
  margin-top: 4px;
`;

const Rating = styled.div`
  display: flex;
  gap: 7px;
`;

const Star = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 15px;
`;

const TestimonialCard: React.FC<TestimonialCardProps> = ({ content, author, position, highlighted }) => {
    return (
        <Card highlighted={highlighted}>
        <Avatar loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/d127874875f9090d66959764808c331cd2e20d07bf492f7fb60ce9acc56955fe?apiKey=dc27afe0f89846c5bd7a442e6ae7c012&&apiKey=dc27afe0f89846c5bd7a442e6ae7c012" alt={author} />
    <Content>{content}</Content>
    <AuthorInfo>
    <AuthorDetails>
        <AuthorName>{author}</AuthorName>
    <AuthorPosition>{position}</AuthorPosition>
    </AuthorDetails>
    <Rating>
    {[...Array(5)].map((_, index) => (
        <Star key={index} loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/837deb4111ca1278157787722de10909a638f9ae753b1d2ff1ca72ec2d6c651f?apiKey=dc27afe0f89846c5bd7a442e6ae7c012&&apiKey=dc27afe0f89846c5bd7a442e6ae7c012" alt="" />
))}
    </Rating>
    </AuthorInfo>
    </Card>
);
};

export default TestimonialCard;
