import React from 'react';
import styled from '@emotion/styled';

interface FeatureProps {
    icon: string;
    title: string;
    description: string;
}

const featureData: FeatureProps[] = [
    {
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/a0c76d95903beff1dde1fa7a4e5391190994d17bd699ea999d636cb7099cb906?apiKey=dc27afe0f89846c5bd7a442e6ae7c012&&apiKey=dc27afe0f89846c5bd7a442e6ae7c012",
        title: "Trusted marketplace",
        description: "Provides a marketplace AI developers to a high quality, exclusive, continuous data from content providers."
    },
    // {
    //     icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/62794a4570802aa0a3d170e89264ff72dd72ffc357e9982936c0414aaa48cfd3?apiKey=dc27afe0f89846c5bd7a442e6ae7c012&&apiKey=dc27afe0f89846c5bd7a442e6ae7c012",
    //     title: "Revenue Generation",
    //     description: "Unlock new revenue streams by leveraging various AI- powered content, transforming your website into a sustainable and profitable digital asset."
    // },
    {
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/c1a0d09533607806b71fc2a09a7d1b76346641c8131804959fb410ce62a3210a?apiKey=dc27afe0f89846c5bd7a442e6ae7c012&&apiKey=dc27afe0f89846c5bd7a442e6ae7c012",
        title: "Content Control and Optimization",
        description: "Gain greater control on how your website consent is used. Leverage AI algorithms to dynamically optimize content."
    },
    {
        icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/c1a0d09533607806b71fc2a09a7d1b76346641c8131804959fb410ce62a3210a?apiKey=dc27afe0f89846c5bd7a442e6ae7c012&&apiKey=dc27afe0f89846c5bd7a442e6ae7c012",
        title: "Data preparation",
        description: "We help publishers get their content or data ready for AI models. We index, benchmark and evaluate data sets to demonstrate their quality and value."
    }
];

const Feature: React.FC<FeatureProps> = ({ icon, title, description }) => (
    <FeatureItem>
        <FeatureContent>
            <FeatureIcon src={icon} alt="" />
            <FeatureTitle>{title}</FeatureTitle>
            <FeatureDescription>{description}</FeatureDescription>
        </FeatureContent>
    </FeatureItem>
);

const Features: React.FC = () => {
    return (
        <FeaturesSection>
            <SectionTitle>A fair and equitable marketplace</SectionTitle>
            <SectionDescription>
                Focus only on the meaning, we take care of the design. As soon as the meeting end you can export in one click into your preferred format.
            </SectionDescription>
            <FeatureList>
                {featureData.map((feature, index) => (
                    <Feature key={index} {...feature} />
                ))}
            </FeatureList>
        </FeaturesSection>
    );
};

const FeaturesSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1277px;
  margin-top: 136px;
  padding: 0 20px;

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const SectionTitle = styled.h2`
  font-family: 'Bree Serif', serif;
  font-size: 30px;
  letter-spacing: -0.5px;
  color: #0f2137;
  text-align: center;
`;

const SectionDescription = styled.p`
  font-family: 'DM Sans', sans-serif;
  font-size: 20px;
  line-height: 35px;
  color: #0f2137;
  text-align: center;
  max-width: 583px;
  margin-top: 10px;
`;

const FeatureList = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 148px;

  @media (max-width: 991px) {
    flex-direction: column;
    margin-top: 40px;
  }
`;

const FeatureItem = styled.div`
  display: flex;
  gap: 20px;
  width: 33%;

  @media (max-width: 991px) {
    width: 100%;
    margin-top: 40px;
  }
`;

const FeatureIcon = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
`;

const FeatureContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const FeatureTitle = styled.h3`
  font-family: 'DM Sans', sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #0f2137;
`;

const FeatureDescription = styled.p`
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  line-height: 30px;
  color: #343d48;
  margin-top: 20px;
`;

export default Features;
