import React from 'react';
import styled from '@emotion/styled';
import Button from './Button';

interface HeroProps {
    // Add any props if needed
}

const HeroSection = styled.section`
  background-color: #fff;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 80px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 40px 20px;
  }
`;

const HeroContent = styled.div`
  width: 100%;
  max-width: 1480px;
  margin: 60px 0 32px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const HeroLayout = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`;

const HeroTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 44%;
  margin-left: 0;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const HeroText = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  font-size: 18px;
  color: #4f9cf9;
  letter-spacing: -0.36px;
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const HeroTitle = styled.h1`
  letter-spacing: -1.28px;
  font: 700 64px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
  }
`;

const HeroDescription = styled.p`
  font-family: Inter, sans-serif;
  font-weight: 400;
  line-height: 30px;
  margin-top: 24px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const HeroImageColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 56%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const HeroImage = styled.div`
  background-color: #c4defd;
  width: 824px;
  max-width: 100%;
  height: 549px;
  margin: 0 auto;
`;

const Hero: React.FC<HeroProps> = () => {
    return (
        <HeroSection>
            <HeroContent>
                <HeroLayout>
                    <HeroTextColumn>
                        <HeroText>
                            <HeroTitle>Get More Done with whitepace</HeroTitle>
                            <HeroDescription>
                                Project management software that enables your teams to collaborate, plan, analyze and manage everyday tasks
                            </HeroDescription>
                            <Button variant="primary" text="Try Whitepace free" icon="arrow-right" />
                        </HeroText>
                    </HeroTextColumn>
                    <HeroImageColumn>
                        <HeroImage />
                    </HeroImageColumn>
                </HeroLayout>
            </HeroContent>
        </HeroSection>
    );
};

export default Hero;
