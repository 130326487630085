import React from 'react';
import styled from '@emotion/styled';

interface NavigationProps {
    // Add any props if needed
}

const NavWrapper = styled.nav`
  display: flex;
  padding-right: 75px;
  gap: 20px;
  color: #4f9cf9;
  white-space: nowrap;
  justify-content: space-between;
  margin: auto 0;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    white-space: initial;
  }
`;

const NavItem = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const NavLink = styled.a`
  font-family: DM Sans, sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  color: inherit;
`;

const NavIcon = styled.img`
  aspect-ratio: 2.27;
  object-fit: auto;
  object-position: center;
  width: 9px;
  margin: auto 0;
  border: 1px solid #4f9cf9;
`;

const Navigation: React.FC<NavigationProps> = () => {
    const navItems = [
        { text: 'Products', href: '#products' },
        { text: 'Solutions', href: '#solutions' },
        { text: 'Resources', href: '#resources' },
        { text: 'Pricing', href: '#pricing' },
    ];

    return (
        <NavWrapper>
            {navItems.map((item, index) => (
                <NavItem key={index}>
                    <NavLink href={item.href}>{item.text}</NavLink>
                    <NavIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/bd5346265c5c99fdc0e21c805d14e9b470326e06ebddb3bf48d75c386c1f1102?apiKey=dc27afe0f89846c5bd7a442e6ae7c012&&apiKey=dc27afe0f89846c5bd7a442e6ae7c012" alt="" />
                </NavItem>
            ))}
        </NavWrapper>
    );
};

export default Navigation;
