import React from 'react';
import styled from '@emotion/styled';
import Button from './Button';

interface FeatureProps {
    title: string;
    description: string;
    buttonText: string;
    imagePosition: 'left' | 'right';
}

const FeatureSection = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 80px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 40px 20px;
  }
`;

const FeatureContent = styled.div`
  width: 100%;
  max-width: 1480px;
  margin: 60px 0 32px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const FeatureLayout = styled.div<{ imagePosition: 'left' | 'right' }>`
  gap: 20px;
  display: flex;
  flex-direction: ${props => props.imagePosition === 'left' ? 'row' : 'row-reverse'};
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`;

const FeatureTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 0;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const FeatureText = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  font-size: 18px;
  color: #212529;
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const FeatureTitle = styled.h2`
  letter-spacing: -1.44px;
  font: 700 72px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
  }
`;

const FeatureDescription = styled.p`
  font-family: Inter, sans-serif;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.36px;
  margin-top: 24px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const FeatureImageColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const FeatureImage = styled.div`
  background-color: #c4defd;
  width: 748px;
  max-width: 100%;
  height: 547px;
  margin: 0 auto;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const Feature: React.FC<FeatureProps> = ({ title, description, buttonText, imagePosition }) => {
    return (
        <FeatureSection>
            <FeatureContent>
                <FeatureLayout imagePosition={imagePosition}>
                    <FeatureTextColumn>
                        <FeatureText>
                            <FeatureTitle>{title}</FeatureTitle>
                            <FeatureDescription>{description}</FeatureDescription>
                            <Button variant="primary" text={buttonText} icon="arrow-right" />
                        </FeatureText>
                    </FeatureTextColumn>
                    <FeatureImageColumn>
                        <FeatureImage />
                    </FeatureImageColumn>
                </FeatureLayout>
            </FeatureContent>
        </FeatureSection>
    );
};

export default Feature;
