import React from 'react';
import styled from '@emotion/styled';

const BusinessBoost: React.FC = () => {
    return (
        <BusinessBoostSection>
            <ContentWrapper>
                <ImageWrapper>
                    <BusinessImage src="https://cdn.builder.io/api/v1/image/assets/TEMP/34b55a39def33f5b0c654b51a5b2502545ffadf2f3bae8e26327565970293620?apiKey=dc27afe0f89846c5bd7a442e6ae7c012&&apiKey=dc27afe0f89846c5bd7a442e6ae7c012" alt="Business boost illustration" />
                </ImageWrapper>
                <ContentColumn>
                    <Title>We help empowering AI vendors and content creators by providing the data while respecting the intellectual property rights of content creators</Title>
                    <Description>
                        We provide mechanism to website owners to publish their valuable content while enabling AI vendors to legally access  and leverage this data.
                    </Description>
                    {/*<FeatureList>*/}
                    {/*    <FeatureItem>*/}
                    {/*        <FeatureIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/6440e6d859a89569d749b99464fcb19169ffd4fa5bebc232a7b9e2de7f7378f4?apiKey=dc27afe0f89846c5bd7a442e6ae7c012&&apiKey=dc27afe0f89846c5bd7a442e6ae7c012" alt="" />*/}
                    {/*        <FeatureText>Unlimited design possibility</FeatureText>*/}
                    {/*    </FeatureItem>*/}
                    {/*    <FeatureItem>*/}
                    {/*        <FeatureIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/95a27f46e8429a54a9fb2fd52ec8af9732732c4b62a2602f83355d7ecc60e9b2?apiKey=dc27afe0f89846c5bd7a442e6ae7c012&&apiKey=dc27afe0f89846c5bd7a442e6ae7c012" alt="" />*/}
                    {/*        <FeatureText>Completely responsive features</FeatureText>*/}
                    {/*    </FeatureItem>*/}
                    {/*</FeatureList>*/}
                    {/*<ExploreButton>*/}
                    {/*    Explore Details*/}
                    {/*    <ArrowIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/13a44fe1ca76e2ce806bfa107a15323a63fbc34a8033757376065ca0a830b6e4?apiKey=dc27afe0f89846c5bd7a442e6ae7c012&&apiKey=dc27afe0f89846c5bd7a442e6ae7c012" alt="" />*/}
                    {/*</ExploreButton>*/}
                </ContentColumn>
            </ContentWrapper>
        </BusinessBoostSection>
    );
};

const BusinessBoostSection = styled.section`
  background-color: #f9fafc;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 73px 60px;
  margin-top: 162px;

  @media (max-width: 991px) {
    margin-top: 40px;
    padding: 40px 20px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  max-width: 1287px;
  width: 100%;

  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const ImageWrapper = styled.div`
  width: 50%;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const BusinessImage = styled.img`
  width: 100%;
  height: auto;
`;

const ContentColumn = styled.div`
  width: 50%;
  padding-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: right;

  @media (max-width: 991px) {
    width: 100%;
    padding-left: 0;
    margin-top: 40px;
  }
`;

const Title = styled.h2`
  font-family: 'Times New Roman', serif;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: -0.5px;
  color: #0f2137;

  @media (max-width: 991px) {
    font-size: 32px;
    line-height: 48px;
  }
`;

const Description = styled.p`
  font-family: 'DM Sans', sans-serif;
  font-size: 20px;
  line-height: 42px;
  color: #02073e;
  margin-top: 20px;
`;

const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 50px;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;

const FeatureIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 11px;
`;

const FeatureText = styled.span`
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  line-height: 45px;
  color: #02073e;
  font-weight: 500;
`;

const ExploreButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #3183ff;
  cursor: pointer;
  margin-top: 51px;
`;

const ArrowIcon = styled.img`
  width: 6px;
  height: 10px;
  margin-left: 14px;
`;

export default BusinessBoost;
