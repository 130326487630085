import React from 'react';
import styled from '@emotion/styled';
import Logo from './Logo';
import Button from './Button';

interface FooterProps {
    // Add any props if needed
}

const FooterWrapper = styled.footer`
  background-color: #043873;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 80px 80px 32px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 40px 20px;
  }
`;

const FooterContent = styled.div`
  display: flex;
  margin-top: 60px;
  width: 100%;
  max-width: 1480px;
  align-items: start;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    margin-top: 40px;
  }
`;

const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FooterDescription = styled.p`
  color: #f7f7ee;
  letter-spacing: -0.36px;
  margin-top: 15px;
  font: 400 18px/30px Inter, sans-serif;
`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  letter-spacing: -0.32px;
`;

const FooterLinkTitle = styled.h3`
  letter-spacing: -0.36px;
  font: 700 18px Inter, sans-serif;
  margin-bottom: 15px;
`;

const FooterLink = styled.a`
  font-family: Inter, sans-serif;
  margin-top: 15px;
  color: #fff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const FooterCTA = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const FooterCTATitle = styled.h3`
  letter-spacing: -0.56px;
  font: 700 28px/129% Inter, sans-serif;
  color: #fff;
`;

const FooterCTADescription = styled.p`
  font-family: Inter, sans-serif;
  line-height: 20px;
  margin-top: 23px;
  color: #fff;
`;

const FooterBottom = styled.div`
  display: flex;
  margin-top: 100px;
  width: 100%;
  max-width: 1480px;
  justify-content: space-between;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    margin-top: 40px;
  }
`;

const FooterBottomLeft = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const LanguageSelector = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  color: #fff;
`;

const LanguageIcon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 19px;
`;

const LanguageText = styled.span`
  color: #fff;
  letter-spacing: -0.32px;
  font: 400 16px Inter, sans-serif;
`;

const LanguageArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 0;
  padding: 4px;
`;

const LanguageArrowIcon = styled.img`
  aspect-ratio: 2;
  object-fit: auto;
  object-position: center;
  width: 12px;
  border: 1px solid #fff;
`;

const FooterLink2 = styled.a`
  color: #fff;
  letter-spacing: -0.32px;
  font: 400 16px Inter, sans-serif;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Copyright = styled.p`
  color: #fff;
  letter-spacing: -0.32px;
  font: 400 16px Inter, sans-serif;
`;

const SocialLinks = styled.div`
  display: flex;
  align-items: start;
  gap: 20px;
  justify-content: space-between;
  margin: auto 0;
`;

const SocialIcon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 15px;
`;

const Footer: React.FC<FooterProps> = () => {
    return (
        <FooterWrapper>
            <FooterContent>
                <FooterColumn>
                    <Logo />
                    <FooterDescription>
                        whitepace was created for the new ways we live and work. We make a better workspace around the world
                    </FooterDescription>
                </FooterColumn>
                <FooterColumn>
                    <FooterLinks>
                        <FooterLinkTitle>Product</FooterLinkTitle>
                        <FooterLink href="#">Overview</FooterLink>
                        <FooterLink href="#">Pricing</FooterLink>
                        <FooterLink href="#">Customer stories</FooterLink>
                    </FooterLinks>
                </FooterColumn>
                <FooterColumn>
                    <FooterLinks>
                        <FooterLinkTitle>Resources</FooterLinkTitle>
                        <FooterLink href="#">Blog</FooterLink>
                        <FooterLink href="#">Guides & tutorials</FooterLink>
                        <FooterLink href="#">Help center</FooterLink>
                    </FooterLinks>
                </FooterColumn>
                <FooterColumn>
                    <FooterLinks>
                        <FooterLinkTitle>Company</FooterLinkTitle>
                        <FooterLink href="#">About us</FooterLink>
                        <FooterLink href="#">Careers</FooterLink>
                        <FooterLink href="#">Media kit</FooterLink>
                    </FooterLinks>
                </FooterColumn>
                <FooterColumn>
                    <FooterCTA>
                        <FooterCTATitle>Try It Today</FooterCTATitle>
                        <FooterCTADescription>
                            Get started for free. Add your whole team as your needs grow.
                        </FooterCTADescription>
                        <Button variant="primary" text="Start today" icon="arrow-right" />
                    </FooterCTA>
                </FooterColumn>
            </FooterContent>
            <FooterBottom>
                <FooterBottomLeft>
                    <LanguageSelector>
                        <LanguageIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/461660734e17ea8f8bd51471a989904e4207d17f832bf1d22c0c36911cd7da9d?apiKey=dc27afe0f89846c5bd7a442e6ae7c012&&apiKey=dc27afe0f89846c5bd7a442e6ae7c012" alt="" />
                        <LanguageText>English</LanguageText>
                        <LanguageArrow>
                            <LanguageArrowIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/62d1402da89273ff42ab78c4ee46e0b90e3035bfa13776c7d5e14f9a138c9bb2?apiKey=dc27afe0f89846c5bd7a442e6ae7c012&&apiKey=dc27afe0f89846c5bd7a442e6ae7c012" alt="" />
                        </LanguageArrow>
                    </LanguageSelector>
                    <FooterLink2 href="#">Terms & privacy</FooterLink2>
                    <FooterLink2 href="#">Security</FooterLink2>
                    <FooterLink2 href="#">Status</FooterLink2>
                    <Copyright>©2021 Whitepace LLC.</Copyright>
                </FooterBottomLeft>
                <SocialLinks>
                    <SocialIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/e361cb5dc4ff2c385e53c1890b8a8aff279d2d0df05d595ed12c65674f6515ad?apiKey=dc27afe0f89846c5bd7a442e6ae7c012&&apiKey=dc27afe0f89846c5bd7a442e6ae7c012" alt="Facebook" />
                    <SocialIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/97cb578a4c426120b30dcdd094cb8c7e389c026eeeeae145f2545e6a9c54079b?apiKey=dc27afe0f89846c5bd7a442e6ae7c012&&apiKey=dc27afe0f89846c5bd7a442e6ae7c012" alt="Twitter" />
                    <SocialIcon loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/a06fa79aa8e970b41eb1e4eb919b6578516e29cd2ab12299ab70bbba4ded36b6?apiKey=dc27afe0f89846c5bd7a442e6ae7c012&&apiKey=dc27afe0f89846c5bd7a442e6ae7c012" alt="LinkedIn" />
                </SocialLinks>
            </FooterBottom>
        </FooterWrapper>
    );
};

export default Footer;
