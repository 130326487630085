import React from 'react';
import styled from '@emotion/styled';

const Hero: React.FC = () => {
    return (
        <HeroSection>
            <HeroContent>
                <HeroTitle>
                    <div>A platform to control and compensate creative works used to train AI</div>
                </HeroTitle>
                <HeroDescription>
                    We’re bringing together Rights holders and AI Developers. Helping rights holders get compensation for copyrighted works. Enabling AI Developers to responsibly acquire high quality data.
                </HeroDescription>
                <SubscribeForm>
                    <EmailInput type="email" id="emailInput" placeholder="Enter Email address" />
                    <SubscribeButton type="submit">Subscribe</SubscribeButton>
                </SubscribeForm>
                {/*<SponsorSection>*/}
                {/*    <SponsorText>Sponsored by:</SponsorText>*/}
                {/*    <SponsorLogo src="https://cdn.builder.io/api/v1/image/assets/TEMP/25b810a1c06880468f85d9a8a352d39f69dc1d59b517e17eb6f970eb724fbe04?apiKey=dc27afe0f89846c5bd7a442e6ae7c012&&apiKey=dc27afe0f89846c5bd7a442e6ae7c012" alt="Sponsor logo" />*/}
                {/*</SponsorSection>*/}
            </HeroContent>
            <HeroImage src="https://cdn.builder.io/api/v1/image/assets/TEMP/b210b49dd3dc0f7e68d173c636787b81863342b199fa9e871f5bbe7c98310a34?apiKey=dc27afe0f89846c5bd7a442e6ae7c012&&apiKey=dc27afe0f89846c5bd7a442e6ae7c012" alt="Hero illustration" />
        </HeroSection>
    );
};

const HeroSection = styled.section`
  display: flex;
  width: 100%;
  max-width: 1352px;
  margin-top: 82px;
  gap: 20px;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    margin-top: 40px;
  }
`;

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  padding: 0 20px;

  @media (max-width: 991px) {
    width: 100%;
    margin-top: 40px;
  }
`;

const HeroTitle = styled.h1`
  font-family: 'Times New Roman', serif;
  font-size: 50px;
  line-height: 80px;
  letter-spacing: -1px;
  color: #02073e;

  @media (max-width: 991px) {
    font-size: 40px;
    line-height: 50px;
  }
`;

const HeroDescription = styled.p`
  font-family: 'DM Sans', sans-serif;
  font-size: 18px;
  line-height: 42px;
  color: #02073e;
  margin-top: 5px;
`;

const SubscribeForm = styled.form`
  display: flex;
  margin-top: 34px;
  gap: 15px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const EmailInput = styled.input`
  flex-grow: 1;
  padding: 24px;
  border: 1px solid #d4dae2;
  border-radius: 5px;
  font-family: 'DM Sans', sans-serif;
  font-size: 18px;
  color: #02073e;

  @media (max-width: 991px) {
    padding: 20px;
  }
`;

const SubscribeButton = styled.button`
  background-color: #f94962;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 23px 27px;
  font-family: 'DM Sans', sans-serif;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;

  @media (max-width: 991px) {
    padding: 20px;
    width: 100%;
  }
`;

const SponsorSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 43px;
  gap: 20px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
    margin-top: 40px;
  }
`;

const SponsorText = styled.span`
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  color: #56627a;
`;

const SponsorLogo = styled.img`
  height: 24px;
`;

const HeroImage = styled.img`
  width: 49%;
  object-fit: contain;

  @media (max-width: 991px) {
    width: 100%;
    margin-top: 40px;
  }
`;

export default Hero;
//
// import React from 'react';
// import {
//     Box,
//     Container,
//     Typography,
//     TextField,
//     Button,
//     Stack,
//     Grid,
//     useMediaQuery,
// } from '@mui/material';
//
// const Hero: React.FC = () => {
//     const isMobile = useMediaQuery('(max-width: 991px)');
//
//     return (
//         <Box sx={{ display: 'flex', top: '20px'}}>
//             <Container maxWidth="md" sx={{ flexGrow: 1, display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
//                 <Box sx={{ width: isMobile ? '100%' : '41%', padding: { xs: 2, sm: 20 } }}>
//                     <Typography variant={isMobile ? 'h4' : 'h1'} component="h1" gutterBottom>
//                         <span>Trusted Data.</span>
//                         <br />
//                         <span>Trusted AI.</span>
//                     </Typography>
//                     <Typography variant="body1" gutterBottom>
//                         A fair way to transform content for unlocking the potential of Artifical Intelligence.
//                     </Typography>
//                     <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
//                         <TextField label="Enter Email address" type="email" id="emailInput" variant="filled" />
//                         <Button variant="contained" type="submit">
//                             Subscribe
//                         </Button>
//                     </Stack>
//                 </Box>
//                 <Grid container item xs={12} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
//                     <Grid item>
//                         <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/b210b49dd3dc0f7e68d173c636787b81863342b199fa9e871f5bbe7c98310a34?apiKey=dc27afe0f89846c5bd7a442e6ae7c012&&apiKey=dc27afe0f89846c5bd7a442e6ae7c012" alt="Hero illustration" />
//                     </Grid>
//                 </Grid>
//             </Container>
//             {/*<SponsorSection>*/}
//             {/*  <SponsorText>Sponsored by:</SponsorText>*/}
//             {/*  <SponsorLogo src="https://cdn.builder.io/api/v1/image/assets/TEMP/25b810a1c06880468f85d9a8a352d39f69dc1d59b517e17eb6f970eb724fbe04?apiKey=dc27afe0f89846c5bd7a442e6ae7c012&&apiKey=dc27afe0f89846c5bd7a442e6ae7c012" alt="Sponsor logo" />*/}
//             {/*</SponsorSection>*/}
//         </Box>
//     );
// };
//
// export default Hero;
