import React from 'react';
import styled from '@emotion/styled';

interface FooterProps {
    // Add any props if needed
}

const Footer: React.FC<FooterProps> = () => {
    return (
        <FooterContainer>
            <FooterColumn>
                <LogoContainer>
                    <LogoImage src="https://cdn.builder.io/api/v1/image/assets/TEMP/ab424314e280b1fa937095c5770f267c5aa939f3aca954677409d1eaf707f4f8?apiKey=dc27afe0f89846c5bd7a442e6ae7c012&&apiKey=dc27afe0f89846c5bd7a442e6ae7c012" alt="Knowlio logo" />
                    <LogoText>Knowlio</LogoText>
                </LogoContainer>
                <FooterText>Terms of use | Privacy</FooterText>
                <FooterText>Copyright by 2024, Knowlio Inc</FooterText>
            </FooterColumn>
            {/*<FooterColumn>*/}
            {/*    <FooterHeading>About Us</FooterHeading>*/}
            {/*    <FooterList>*/}
            {/*        <FooterListItem>Support Center</FooterListItem>*/}
            {/*        <FooterListItem>Customer Support</FooterListItem>*/}
            {/*        <FooterListItem>About Us</FooterListItem>*/}
            {/*        <FooterListItem>Copyright</FooterListItem>*/}
            {/*    </FooterList>*/}
            {/*</FooterColumn>*/}
            {/*<FooterColumn>*/}
            {/*    <FooterHeading>Connect</FooterHeading>*/}
            {/*    <SocialContainer>*/}
            {/*        <SocialIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/1cec4097aa6cbaef19e3ae5137311ba2ba49502d74e7bd2925f75a01382d490b?apiKey=dc27afe0f89846c5bd7a442e6ae7c012&&apiKey=dc27afe0f89846c5bd7a442e6ae7c012" alt="Social media icon" />*/}
            {/*        <SocialList>*/}
            {/*            <SocialListItem>Facebook</SocialListItem>*/}
            {/*            <SocialListItem>Twitter</SocialListItem>*/}
            {/*            <SocialListItem>Github</SocialListItem>*/}
            {/*        </SocialList>*/}
            {/*    </SocialContainer>*/}
            {/*</FooterColumn>*/}
        </FooterContainer>
    );
};

const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  width: 1068px;
  max-width: 100%;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    margin-top: 40px;
  }
`;

const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogoContainer = styled.div`
  display: flex;
  gap: 14px;
  font-size: 20px;
  color: #02073e;
  font-weight: 700;
  white-space: nowrap;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const LogoImage = styled.img`
  aspect-ratio: 0.73;
  object-fit: contain;
  width: 27px;
`;

const LogoText = styled.span`
  font-family: 'SF Pro Display', sans-serif;
  margin: auto 0;
`;

const FooterText = styled.p`
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  color: #0f2137;
  margin-top: 25px;
`;

const FooterHeading = styled.h3`
  letter-spacing: -0.5px;
  color: #0f2137;
  font: 500 18px 'DM Sans', sans-serif;
`;

const FooterList = styled.ul`
  list-style-type: none;
  padding: 0;
  @media (max-width: 991px) {
  }
`;

const FooterListItem = styled.li`
  color: #02073e;
  font: 400 15px/38px 'DM Sans', sans-serif;
`;

const SocialContainer = styled.div`
  display: flex;
  gap: 14px;
  @media (max-width: 991px) {
    
  }
`;

const SocialIcon = styled.img`
  aspect-ratio: 0.15;
  object-fit: contain;
  width: 30px;
`;

const SocialList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const SocialListItem = styled.li`
  font-family: 'DM Sans', sans-serif;
  font-size: 15px;
  color: #02073e;
  line-height: 38px;
`;

export default Footer;
