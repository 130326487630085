import React from 'react';
import styled from '@emotion/styled';
import Button from './Button';

interface PricingCardProps {
    title: string;
    price: string;
    features: string[];
    buttonText: string;
    highlighted?: boolean;
}

const Card = styled.div<{ highlighted?: boolean }>`
  border-radius: 10px;
  background-color: ${props => props.highlighted ? '#4f9cf9' : '#fff'};
  box-shadow: ${props => props.highlighted ? '0px 4px 50px rgba(0, 0, 0, 0.08)' : 'none'};
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  max-width: 400px;
  padding: ${props => props.highlighted ? '80px 44px' : '40px 44px'};
  border: ${props => props.highlighted ? 'none' : '1px solid #ffe492'};
  color: ${props => props.highlighted ? '#fff' : '#212529'};
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 32px;
    padding: 40px 20px;
  }
`;

const Title = styled.h3`
  font: 600 24px/150% Inter, sans-serif;
`;

const Price = styled.div`
  letter-spacing: -0.72px;
  margin-top: 25px;
  font: 700 36px Inter, sans-serif;
`;

const Description = styled.p`
  letter-spacing: -0.36px;
  margin-top: 25px;
  font: 500 18px/128% Inter, sans-serif;
`;

const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 25px;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  gap: 19px;
  letter-spacing: -0.32px;
  margin-top: 28px;
  font-family: Inter, sans-serif;
`;

const FeatureIcon = styled.img`
  aspect-ratio: 0.94;
  object-fit: auto;
  object-position: center;
  width: 18px;
`;

const PricingCard: React.FC<PricingCardProps> = ({ title, price, features, buttonText, highlighted }) => {
    return (
        <Card highlighted={highlighted}>
            <Title>{title}</Title>
            <Price>{price}</Price>
            <Description>Capture ideas and find them quickly</Description>
            <FeatureList>
                {features.map((feature, index) => (
                    <FeatureItem key={index}>
                        <FeatureIcon loading="lazy" src={`http://b.io/ext_${highlighted ? '11' : '9'}-`} alt="" />
                        {feature}
                    </FeatureItem>
                ))}
            </FeatureList>
            <Button variant={highlighted ? "primary" : "secondary"} text={buttonText} />
        </Card>
    );
};

export default PricingCard;
