import React from 'react';
import styled from '@emotion/styled';
import Header from './Header';
import Footer from './Footer';

interface LayoutProps {
    children: React.ReactNode;
}

const Main = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.div`
  flex: 1;
`;

const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <Main>
            <Header />
            <Content>{children}</Content>
            <Footer />
        </Main>
    );
};

export default Layout;
