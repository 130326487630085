import React from 'react';
import styled from '@emotion/styled';

interface LogoProps {
    // Add any props if needed
}

const LogoWrapper = styled.div`
  display: flex;
  gap: 10px;
  font-size: 28px;
  color: #4f9cf9;
  font-weight: 700;
  white-space: nowrap;
  margin: auto 0;
  padding: 2px 0;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const LogoImage = styled.img`
  aspect-ratio: 1.28;
  object-fit: auto;
  object-position: center;
  width: 37px;
  align-self: start;
`;

const LogoText = styled.div`
  font-family: Inter, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
`;

const Logo: React.FC<LogoProps> = () => {
    return (
        <LogoWrapper>
            <LogoImage loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/de75e6430a4bacee585761e62b3a69590d2078b461d882f06273c3fd7581d930?apiKey=dc27afe0f89846c5bd7a442e6ae7c012&&apiKey=dc27afe0f89846c5bd7a442e6ae7c012" alt="Knowlio logo" />
            <LogoText>Knowlio</LogoText>
        </LogoWrapper>
    );
};

export default Logo;
